import PropTypes from 'prop-types';
import React from 'react';

const ExternalLink = ({ title, url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {title}
  </a>
);

ExternalLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ExternalLink;
