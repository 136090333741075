import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import sections from '../../assets/data/sections';

class NavbarMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 50,
    };
  }

  renderLinks = () => {
    const { offset } = this.state;

    return sections.map(section => (
      <AnchorLink
        key={section.name}
        href={`#${section.name}`}
        offset={offset}
        className="navbar-item"
      >
        {section.name}
      </AnchorLink>
    ));
  }

  render() {
    const { isMenuActive } = this.props;

    const navbarMainClasses = classNames({
      'navbar-menu': true,
      'is-active': isMenuActive,
    });

    return (
      <div id="navbar-main" className={navbarMainClasses}>
        <div className="navbar-end">{this.renderLinks()}</div>
      </div>
    );
  }
}

NavbarMain.propTypes = {
  isMenuActive: PropTypes.bool.isRequired,
};

export default NavbarMain;
