import React from 'react';
import PropTypes from 'prop-types';

import Header from '../header/Header';
import Footer from '../footer/Footer';

import strings from '../../assets/lang/en';
import '../../assets/scss/main.scss';

const Layout = ({ children }) => (
  <React.Fragment>
    <Header siteTitle={strings.general.title} />
    <main>{children}</main>
    <Footer />
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
