import React, { Component } from 'react';

import { generateKey } from '../../js/common';
import quotes from '../../assets/data/quotes';

class Quotes extends Component {
  renderQuotes = () =>
    Object.values(quotes).map((quote) => {
      const key = generateKey(quote.quote);
      return (
        <li key={key}>
          <span className="quote">{`${quote.quote}`}</span>
          <span className="author">{quote.author}</span>
        </li>
      );
    })

  render() {
    return (
      <div className="category credits">
        <h2 className="title">Credits</h2>
        <ul>{this.renderQuotes()}</ul>
      </div>
    );
  }
}

export default Quotes;
