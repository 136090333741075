import React, { Component } from 'react';

import ExternalLink from '../common/ExternalLink';
import licenses from '../../assets/data/licenses';
import { generateKey } from '../../js/common';

class Licenses extends Component {
  renderLicenses = () =>
    Object.values(licenses).map((license) => {
      const key = generateKey(license.title);
      return (
        <li key={key}>
          <ExternalLink title={license.title} url={license.url} />
        </li>
      );
    })

  render() {
    return (
      <div className="category licenses">
        <h2 className="title">Licences</h2>
        <ul>{this.renderLicenses()}</ul>
      </div>
    );
  }
}

export default Licenses;
