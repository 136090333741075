const sections = [
  {
    name: 'home',
    icon: '',
  },
  {
    name: 'presentation',
    icon: '',
  },
  {
    name: 'background',
    icon: '',
  },
  {
    name: 'skills',
    icon: '',
  },
  {
    name: 'contact',
    icon: '',
  },
];
export default sections;
