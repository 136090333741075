import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { isScrolled: false };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ isScrolled: window.scrollY > 25 });
  }

  render() {
    const { isScrolled } = this.state;
    const { children, isMenuActive } = this.props;

    const navbarClasses = classNames({
      navbar: true,
      'is-fixed-top': true,
      'has-background': isScrolled || isMenuActive,
    });

    return (
      <nav className={navbarClasses} role="navigation" aria-label="main navigation">
        <div className="container">{children}</div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
  isMenuActive: PropTypes.bool.isRequired,
};

export default Navbar;
