const licenses = {
  gatsby: {
    title: 'Gatsby',
    url: 'https://gatsbyjs.org',
  },
  bulma: {
    title: 'Bulma',
    url: 'https://bulma.io',
  },
  bulmaTimeline: {
    title: 'Bulma timeline',
    url: 'https://wikiki.github.io/components/timeline/',
  },
  typedjs: {
    title: 'Typed.js',
    url: 'https://github.com/mattboldt/typed-react.js',
  },
  particlesjs: {
    title: 'Particles.js',
    url: 'https://github.com/VincentGarreau/particles.js/',
  },
  RCProgress: {
    title: 'RC Progress',
    url: 'https://github.com/react-component/progress',
  },
  recatDeviceDetect: {
    title: 'React device detect',
    url: 'https://github.com/duskload/react-device-detect',
  },
  RALSS: {
    title: 'React Anchor Link Smooth Scroll',
    url: 'https://github.com/mauricevancooten/react-anchor-link-smooth-scroll',
  },
};

export default licenses;
