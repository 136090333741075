const strings = {
  general: {
    title: 'Dimitri Tworowski - Curriculum Vitae',
    author: 'Dimitri Tworowski',
    description: 'Dimitri Tworowski - Curriculum Vitae',
  },
  sections: {
    home: 'Home',
    presentation: 'Presentation',
    background: 'Background',
    skills: 'Skills',
    projects: 'Projects',
    contact: 'Contact',
  },
  typed: ['web developer', 'jack of all trades', 'pretty cool unicorn'],
  presentationText: {
    name: {
      icon: '',
      text: 'Dimitri Tworowski',
    },
    birthday: {
      icon: 'birthday',
      text: '19/01/1994',
    },
    person: {
      icon: 'person',
      text: 'Quiet & sociable, sprinkled with great sense of humour',
    },
    heart: {
      icon: 'heart',
      text: 'Basket, design and new technologies',
    },
    car: {
      icon: 'car',
      text: 'B driving license',
    },
    french: {
      icon: 'language',
      text: 'French - Mother tongue',
    },
    english: {
      icon: 'language',
      text: 'English - B2/C1 level',
    },
  },
};

export default strings;
