import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import sections from '../../assets/data/sections';
import logo from '../../assets/images/logo.png';

const NavbarBrand = (props) => {
  const { isMenuActive, onClick } = props;

  const navbarBurgerClasses = classNames({
    'navbar-burger': true,
    burger: true,
    'is-active': isMenuActive,
  });

  return (
    <div className="navbar-brand">
      <AnchorLink href={`#${sections[0].name}`} className="navbar-item">
        <img src={logo} alt="logo" width="120" />
      </AnchorLink>
      <button
        href="/"
        type="button"
        className={navbarBurgerClasses}
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar-main"
        onClick={onClick}
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </button>
    </div>
  );
};

NavbarBrand.propTypes = {
  isMenuActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NavbarBrand;
