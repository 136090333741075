import React, { Component } from 'react';

import Modal from '../common/Modal';
import Licenses from './Licenses';
import Quotes from './Quotes';

class Footer extends Component {
  renderCopyright = () => `© ${new Date().getFullYear()} - Dimitri Tworowski`

  render() {
    return (
      <footer>
        <div className="container">
          <div className="level">
            <div className="level-left">
              <div className="level-item">{this.renderCopyright()}</div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Modal title="Credits & licences">
                  <div className="content">
                    <Licenses />
                    <Quotes />
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
