const quotes = {
  presentation: {
    quote: "« I'm not trying to be different. To me, I'm just being myself. »",
    author: 'Jaylen Brown',
  },
  background: {
    quote:
      '« Yesterday is history, tomorrow is a mystery, today is a gift of God, which is why we call it the present. »',
    author: 'Bill Keane',
  },
  skill: {
    quote: "« Excellence is not a skill, it's an attitude. »",
    author: 'Ralph Marston',
  },
  projects: {
    quote: '« Every project has challenges, and every project has its rewards. »',
    author: 'Stephen Schwartz',
  },
  contact: {
    quote: '« If opportunity doesn’t knock, build a door. »',
    author: 'Milton Berle',
  },
};

export default quotes;
