import React, { Component } from 'react';

import Navbar from './Navbar';
import NavbarBrand from './NavbarBrand';
import NavbarMain from './NavbarMain';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuActive: false,
    };
  }

  onMenuToggle = () => {
    const { isMenuActive } = this.state;

    this.setState({ isMenuActive: !isMenuActive });
  }

  render() {
    const { isMenuActive } = this.state;

    return (
      <header>
        <Navbar isMenuActive={isMenuActive}>
          <NavbarBrand isMenuActive={isMenuActive} onClick={this.onMenuToggle} />
          <NavbarMain isMenuActive={isMenuActive} />
        </Navbar>
      </header>
    );
  }
}

export default Header;
