import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { isActive: false };
  }

  toggleModal = () => {
    const { isActive } = this.state;

    this.setState({ isActive: !isActive });

    document.body.classList.toggle('fixed');
  }

  render() {
    const { isActive } = this.state;
    const { title, children } = this.props;

    const modalClasses = classNames({
      modal: true,
      'is-active': isActive,
    });

    return (
      <div className="modal-container">
        <button type="button" className="modal-title" onClick={this.toggleModal}>
          {title}
        </button>
        <div className={modalClasses}>
          <div className="modal-background" />
          <div className="modal-content container">{children}</div>
          <button
            type="button"
            className="modal-close is-large"
            aria-label="close"
            onClick={this.toggleModal}
          />
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
