import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import strings from '../../assets/lang/en';

const SEO = ({ lang, keywords, meta }) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={strings.title}
    titleTemplate={`%s | ${strings.title}`}
    meta={[
      {
        name: 'description',
        content: strings.general.title,
      },
      {
        property: 'og:title',
        content: strings.general.title,
      },
      {
        property: 'og:description',
        content: strings.general.description,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:creator',
        content: strings.general.author,
      },
      {
        name: 'twitter:title',
        content: strings.general.title,
      },
      {
        name: 'twitter:description',
        content: strings.general.description,
      },
    ]
      .concat(
        keywords.length > 0
          ? {
            name: 'keywords',
            content: keywords.join(', '),
          }
          : [],
      )
      .concat(meta)}
  />
);

SEO.defaultProps = {
  lang: 'en',
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.arrayOf(PropTypes.string),
};

export default SEO;
